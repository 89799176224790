import axios from 'axios';
import React, { useEffect, useState } from 'react'

import { Col, Modal, Row, ToastContainer } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Box, HeadingStyled, LabelStyled, SpanStyled } from '../../Styled/Elements';
import { ButtonWithIcon, ContactButton } from '../../Styled/CustomElements';
import { AddBtn, ButtonIcon } from '../../Styled/AllImages';
import { LeadFormWrapper } from './style';
import FormData from 'form-data';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LeadForm = () => {

    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir() === 'rtl';
    const [isLoading, setIsLoading] = useState(false);
    const [showBox, setShowBox] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [propertyType, setPropertyType] = useState("");
    const [bedroomActive, setBedroomActive] = useState(false);
    const [propertyState, setPropertyState] = useState({
        Apartment: false,
        Villa: false,
        Palace: false,
        Land: false,
        Building: false,
        Chalet: false,
        Farm: false,
        Duplex: false,
        Floor: false,
        RestHouse: false
    });


    // const handleSelectorChange = (e) => {
    //     const newPropertyType = e.target.value;

    //     setPropertyType(newPropertyType);
    //     console.log(newPropertyType);
    //     setBedroomActive(propertyState[newPropertyType]); // Set bedroomActive based on the new property type
    //     // console.log('property-type:', propertyType)
    //     // console.log('property-type-bedroom:', bedroomActive)
    // };

    const handleSelectorChange = (e, id) => {
        let propertyName;
        if (id === 1) {
            propertyName = t("Residential")
        } else {
            propertyName = t("Commercial")
        }
        const newPropertyType = propertyName;

        // Reset propertyState when switching property type
        const resetPropertyState = Object.keys(propertyState).reduce((acc, property) => {
            acc[property] = false;
            return acc;
        }, {});
        setPropertyState(resetPropertyState);
        console.log('new_____', newPropertyType)

        // Reset bedroomState when switching property type
        const resetBedroomState = Object.keys(bedroomState).reduce((acc, bedroom) => {
            acc[bedroom] = false;
            return acc;
        }, {});
        setBedroomState(resetBedroomState);

        setPropertyType(newPropertyType);
        setBedroomActive(propertyState[newPropertyType]); // Set bedroomActive based on the new property type
        setShowBox(false);
    };

    const handlePropertyChange = (property) => {
        const newPropertyState = {
            ...propertyState,
            [property]: !propertyState[property]
        };
        setPropertyState(newPropertyState);

        // Check if any property other than "Land" and "Farm" is active
        const isActiveProperty = Object.keys(newPropertyState)
            .filter(prop => prop !== "Land" && prop !== "Farm")
            .some(prop => newPropertyState[prop]);
        // Set bedroomActive to true only if at least one property is active
        setBedroomActive(isActiveProperty);

        // find the single property data 
        const truePropertyData = Object.entries(newPropertyState)
            .filter(([key, value]) => value === true);
        console.log(truePropertyData);
    };

    const [bedroomState, setBedroomState] = useState({

        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
        Plus9: false,
        Studio: false,
    });


    const handleBedroomChange = (bedroom) => {
        const newBedroomState = {
            ...bedroomState,
            [bedroom]: !propertyState[bedroom]
        };
        setBedroomState(newBedroomState);
        // find the single bedroom data 
        const trueBedroomData = Object.entries(newBedroomState)
            .filter(([key, value]) => value === true);
        console.log(trueBedroomData);
    }
    const handleShowroomChange = (e) => {
        setShowBox(e.target.checked);
    };



    const handleAddDistricts = () => setShowModal(true);
    const [cityData, setCityData] = useState([]);
    const [selectedCity, setSelectedCity] = useState();
    const [selectedDistricts, setSelectedDistricts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [cityNameSelect, setCityNameSelect] = useState('');
    const [districts, setDistricts] = useState([]);
    const [selectedDistrictNames, setSelectedDistrictNames] = useState([]);



    const handleCityChange = (e) => {
        const city = e.target.value;
        setSelectedCity(city);
        getDistrictData(city);
        setShowModal(true);
        setSelectedDistricts([])
        setSelectedDistrictNames([])
    };
    const handleClose = () => {
        setShowModal(false);
    }
    const handleContinueClick = () => {
        setIsConfirm(true);
        setShowModal(false);
    };
    const getCityData = async () => {
        try {
            const response = await axios.get("https://ahouseleads.app4orce.com/api/public/api/v1/get-all-cities");
            if (!response.data) {
                throw new Error('Response error, please check the response');
            } else {
                // console.log('city data', response.data.data[0].name_ar);
                setCityData(response.data.data); // Update cityData state with fetched data
                // console.log("State____", response.data.data);
            }
        } catch (error) {
            console.log(error.message);
        }
    }
    const getDistrictData = async (id) => {
        try {
            const response = await axios.post("https://ahouseleads.app4orce.com/api/public/api/v1/get-distirct", { city_id: id });
            if (!response.data) {
                throw new Error('Response error, please check the response');
            } else {
                // console.log('city data', response.data.data[0].name_ar);
                setDistricts(response.data.data);
                console.log("district__data", response.data.data)
            }
        } catch (error) {
            console.log(error.message);
        }
    }
    useEffect(() => {
        getCityData();

    }, []);
    useEffect(() => {
        const cityName = cityData.find(city => city.city_id == selectedCity)?.name_en;
        const cityNameInRTL = cityData.find(city => city.city_id == selectedCity)?.name_ar;
        const selectedCityName = isRTL ? cityNameInRTL : cityName;
        // console.log('__', selectedCityName);

        setCityNameSelect(selectedCityName || '');
    }, [selectedCity, cityData, isRTL]);
    const handleDistrictChange = (e) => {
        const districtId = parseInt(e.target.id);
        const districtName = districts.find(district => district.id === districtId);
        const singleName = isRTL ? districtName.name_ar : districtName.name_en;
        if (e.target.checked) {
            if (!selectedDistricts.includes(districtId) && selectedDistricts.length < 5) {
                setSelectedDistricts(prevDistricts => [...prevDistricts, districtId]);
                setSelectedDistrictNames(prevNames => [...prevNames, singleName]); // Add district name
                console.log('select_district_Name', districtId)
            }
        } else {
            setSelectedDistricts(prevDistricts => prevDistricts.filter(district => district !== districtId));
            setSelectedDistrictNames(prevNames => prevNames.filter(name => name !== singleName)); // Remove district name
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        selectedDistricts.map((id, index) => (
            formData.append('districts_id[]', id)
        ))

        // const formErrors = validateForm(formData);
        if (validateForm(formData)) {
            try {
                setIsLoading(true);
                const response = await axios.post("https://ahouseleads.app4orce.com/api/public/api/v1/save-property-info", formData);
                if (response.status === 200) {
                    // Clear selected districts
                    setSelectedCity();
                    setPropertyType("");
                    setBedroomActive(false);
                    setSelectedDistricts([]);
                    setSelectedDistrictNames([]);
                    setPropertyState({
                        Apartment: false,
                        Villa: false,
                        Palace: false,
                        Land: false,
                        Building: false,
                        Chalet: false,
                        Farm: false,
                        Duplex: false,
                        Floor: false,
                        RestHouse: false
                    })

                    // Clear form fields
                    e.target.reset();
                    // toast.success("Your submission was successful.", { autoClose: 3000 });
                    toast.success(t('submission'), {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                        // className: 'message'
                    })
                    setIsLoading(false);
                } else {
                    // Clear selected districts
                    setSelectedDistricts([]);
                    setSelectedDistrictNames([]);

                    // Clear form fields
                    e.target.reset();

                    setSelectedCity();
                    setPropertyType("");
                    setBedroomActive(false);
                    setSelectedDistricts([]);
                    setSelectedDistrictNames([]);
                    setPropertyState({
                        Apartment: false,
                        Villa: false,
                        Palace: false,
                        Land: false,
                        Building: false,
                        Chalet: false,
                        Farm: false,
                        Duplex: false,
                        Floor: false,
                        RestHouse: false
                    })
                    toast.error(t('submission_fail'), {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setIsLoading(false);
                }
            } catch (error) {
                console.error("Error submitting the form:", error);
                toast.error(t('submission_fail'), {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setIsLoading(false);
            } finally {
                // console.log(selectedDistricts);
            }
        }
    }
    const [formErrors, setFormErrors] = useState({
        // propertyMain: '',
        // propertyCategory: '',
        // propertyType: '',
        // priceRange: '',
        // fullName: '',
        // phoneNumber: '',
        // cityId: '',
        // customerType: '',

        // Add more fields as needed
    });
    const validateForm = (formData) => {
        const errors = {};

        const propertyMain = formData.get('property_main');
        if (!propertyMain) {
            errors.propertyMain = t('Select_any_one');
        }

        const propertyCategory = formData.get('property_category');
        if (!propertyCategory) {
            errors.propertyCategory = t('Select_any_one');
        }


        // const propertyType = formData.get('property_type');
        // if (!propertyType) {
        //     errors.propertyType = 'Select property types ';
        // }

        // const bedrooms = formData.get('bedrooms');
        // if (!bedrooms) {
        //     errors.bedrooms = 'Select the bedrooms ';
        // }

        const priceRange = formData.get('price_range');
        if (!priceRange) {
            errors.priceRange = t('Select_the_price_range');
        }
        const fullName = formData.get('full_name');
        if (!fullName) {
            errors.fullName = t('Name_requried');
        }

        const phoneNumber = formData.get('phone_number');
        if (!phoneNumber) {
            errors.phoneNumber = t('Phone_requried');
        }
        const cityId = formData.get('city_id');
        if (!cityId) {
            errors.cityId = t('Select_city');
        }
        // const customerType = formData.get('customer_type');
        // if (!customerType) {
        //     errors.customerType = 'Select the city';
        // }

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors)
        } else {
            setFormErrors({})
        }
        return Object.keys(errors).length === 0
    };

    return (
        <LeadFormWrapper>
            <form className='py-3' onSubmit={handleSubmit}>
                <Row>
                    <Col lg={12} md={12}>
                        <Box className='field-box border-bottom-line'>
                            <HeadingStyled className='label-heading' >{t('looking_property')}</HeadingStyled>
                            <Box className='active-radio-container'>
                                <input type="radio" id='buy' value={t('Buy')} name="property_main" />
                                <LabelStyled className='input-field' for='buy'>{t('Buy')}</LabelStyled>
                                <input type="radio" id='rent' value={t('Rent')} name="property_main" />
                                <LabelStyled className='input-field' for='rent'>{t('Rent')}</LabelStyled>
                                <input type="radio" id='Sale' value={t('Sale')} name="property_main" />
                                <LabelStyled className='input-field' for='Sale'>{t('Sale')}</LabelStyled>
                            </Box>
                            {formErrors.propertyMain && <div className="error-message">{formErrors.propertyMain}</div>}
                        </Box>
                    </Col>
                    <Col lg={12} md={12}>
                        <Box className='field-box border-bottom-line'>
                            <Box className='property-categories my-2'>
                                <HeadingStyled className='label-heading mb-2'>{t('Property_category')} </HeadingStyled>
                                <Box className='active-radio-container'>
                                    <input type="radio" id='Residential' value={propertyType} name="property_category" onClick={(e) => handleSelectorChange(e, 1)} />
                                    <LabelStyled className='input-field' for='Residential'>{t('Residential')}</LabelStyled>
                                    <input type="radio" id='Commercial' value={propertyType} name="property_category" onClick={(e) => handleSelectorChange(e, 2)} />
                                    <LabelStyled className='input-field' for='Commercial'>{t('Commercial')}</LabelStyled>
                                </Box>
                                {formErrors.propertyCategory && <div className="error-message">{formErrors.propertyCategory}</div>}
                                {propertyType === t("Residential") && (
                                    <Box className='property-type-container common-space'>
                                        <HeadingStyled size='14px' className='mb-2'>{t('Property_Types')} </HeadingStyled>
                                        <Box className='active-check-container'>
                                            {Object.keys(propertyState).map((property) => (
                                                <React.Fragment key={property}>
                                                    <input
                                                        type="checkbox"
                                                        id={property}
                                                        value={t(property)}
                                                        name='property_type[]'
                                                        checked={propertyState[property]}
                                                        onChange={() => handlePropertyChange(property)}
                                                    />
                                                    <LabelStyled className='input-field' htmlFor={property}>{t(property)}</LabelStyled>
                                                </React.Fragment>
                                            ))}
                                        </Box>
                                        {formErrors.propertyType && <div className="error-message">{formErrors.propertyType}</div>}
                                    </Box>
                                )}

                                {propertyType === t("Commercial") && (
                                    <Box className='Commercial-container common-space'>
                                        <HeadingStyled size='14px' className='mb-2' >{t('Property_Types')}</HeadingStyled>
                                        <Box className='active-check-container'>
                                            <input type="checkbox" id='Office-Space' value={t('Office_Space')} name="property_type[]" />
                                            <LabelStyled className='input-field' for='Office-Space'>{t('Office_Space')} </LabelStyled>

                                            <input type="checkbox" id='Commercial-Building' value={t('Building')} name="property_type[]" />
                                            <LabelStyled className='input-field' for='Commercial-Building'>  {t('Building')} </LabelStyled>

                                            <input type="checkbox" id='Showroom' value={t('Showroom')} name="property_type[]" onChange={handleShowroomChange} />
                                            <LabelStyled className='input-field' for='Showroom'>  {t('Showroom')}</LabelStyled>

                                            <input type="checkbox" id='Commercial-Land' value={t('Land')} name="property_type[]" />
                                            <LabelStyled className='input-field' for='Commercial-Land'>{t('Land')} </LabelStyled>
                                        </Box>
                                        {formErrors.propertyType && <div className="error-message">{formErrors.propertyType}</div>}
                                        {showBox && (
                                            <Box className="check-box d-flex align-items-center gap-2 my-2">
                                                <input type="checkbox" name='terms_conditions' value={t('Inside_Mall_Only')} id="terms-conditions" />
                                                <LabelStyled className="LabelStyled" size="14px" htmlFor="terms-conditions"> {t('Inside_Mall_Only')} </LabelStyled>
                                            </Box>
                                        )}
                                    </Box>
                                )}

                                {bedroomActive && (
                                    <Box className='bedroom-container common-space'>
                                        <HeadingStyled size='14px' className='mb-2' >{t('Bedrooms')}</HeadingStyled>
                                        <Box className='active-check-container'>
                                            {Object.keys(bedroomState).map((bedroom) => (
                                                <React.Fragment key={bedroom}>
                                                    <input
                                                        type="checkbox"
                                                        id={bedroom}
                                                        value={t(bedroom)}
                                                        name='bedrooms[]'
                                                        checked={bedroomState[bedroomState]}
                                                        onChange={() => handleBedroomChange(bedroom)}
                                                    />
                                                    <LabelStyled className='input-field' htmlFor={bedroom}>{t(bedroom)}</LabelStyled>
                                                </React.Fragment>
                                            ))}
                                        </Box>
                                        {formErrors.bedrooms && <div className="error-message">{formErrors.bedrooms}</div>}
                                    </Box>
                                )}
                            </Box>
                            <Box className='price-range my-2'>
                                <HeadingStyled className='label-heading mb-2'>{t('Price_Range')}</HeadingStyled>
                                <Box className='active-radio-container'>
                                    <input type="radio" id='more-2m' value={t('More_Then_2M')} name="price_range" />
                                    <LabelStyled className='input-field' for='more-2m'> {t('More_Then_2M')} </LabelStyled>

                                    <input type="radio" id='1m-2m' value={t('1M_To_2M')} name="price_range" />
                                    <LabelStyled className='input-field' for='1m-2m'> {t('1M_To_2M')} </LabelStyled>

                                    <input type="radio" id='500k-2m' value={t('500K_To_1M')} name="price_range" />
                                    <LabelStyled className='input-field' for='500k-2m'> {t('500K_To_1M')}</LabelStyled>

                                    <input type="radio" id='less-500k' value={t('Less_Then_500k')} name="price_range" />
                                    <LabelStyled className='input-field' for='less-500k'>{t('Less_Then_500k')} </LabelStyled>
                                </Box>
                                {formErrors.priceRange && <div className="error-message">{formErrors.priceRange}</div>}
                            </Box>
                        </Box>
                    </Col>

                    <Col lg={6} md={12}>
                        <Box className='field-box'>
                            <HeadingStyled className='label-heading'>{t('Full_Name')}  </HeadingStyled>
                            <input className='input-field' type="text" name='full_name' placeholder={t('Full_Name_place')} />
                            {formErrors.fullName && <div className="error-message">{formErrors.fullName}</div>}
                        </Box>
                    </Col>
                    <Col lg={6} md={12}>
                        <Box className='field-box'>
                            <HeadingStyled className='label-heading'> {t('Phone_Number')}  </HeadingStyled>
                            <input className='input-field' type="number" name='phone_number' placeholder={t('Phone_Number_place')} />
                        </Box>
                        {formErrors.phoneNumber && <div className="error-message">{formErrors.phoneNumber}</div>}
                    </Col>
                    <Col lg={12} md={12}>
                        <Box className='field-box border-bottom-line'>
                            <HeadingStyled className='label-heading'>{t('City')}</HeadingStyled>

                            <select name='city_id' value={selectedCity} onChange={handleCityChange}>
                                <option value="">{t('Select_City')}</option>
                                {cityData.map((city, index) => {
                                    const isRTL = i18n.dir() === 'rtl';
                                    const { city_id, name_ar, name_en } = city;
                                    const cityName = isRTL ? name_ar : name_en;

                                    return (
                                        <option key={index} value={city_id}>{cityName}</option>
                                    );
                                })}
                            </select>
                            {formErrors.cityId && <div className="error-message">{formErrors.cityId}</div>}

                            {selectedCity && (
                                <Box className='field-box'>
                                    <SpanStyled size='12px' className='label-heading'>{t('District')}</SpanStyled>
                                    <Box className='d-flex align-items-center flex-wrap gap-2'>
                                        <ButtonWithIcon type="button" icon={AddBtn} label={t('Add_districts')} onClick={handleAddDistricts} />
                                        {isConfirm && (
                                            <Box className='selected-data-show'>
                                                {selectedDistrictNames.map((name, index) => (
                                                    <span key={index}>{name}</span>
                                                ))}
                                            </Box>
                                        )}

                                    </Box>
                                </Box>


                            )}
                            {showModal && (
                                <Modal size='xl' show={showModal} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title> {t('Select_Districts_in')} {cityNameSelect}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Box className='select-district'>
                                            {districts.length > 0 ? (
                                                districts.map((district, index) => {
                                                    const { id, name_ar, name_en } = district;
                                                    const districtName = isRTL ? name_ar : name_en;
                                                    return (
                                                        <Box key={index} className="check-box d-flex align-items-center gap-2 my-2">
                                                            <input
                                                                type="checkbox"
                                                                id={id}
                                                                name='districts_id[]'
                                                                value={id}
                                                                checked={selectedDistricts.includes(id)}
                                                                onChange={handleDistrictChange}
                                                                disabled={selectedDistricts.length === 5 && !selectedDistricts.includes(id)}
                                                            />
                                                            <LabelStyled htmlFor={id} className="LabelStyled" style={{ fontSize: '14px' }}>{districtName}</LabelStyled>
                                                        </Box>
                                                    );
                                                })
                                            ) : (
                                                <Box className="check-box d-flex align-items-center gap-2 my-2">
                                                    <LabelStyled className="LabelStyled" style={{ fontSize: '14px' }}>{cityNameSelect}</LabelStyled>
                                                </Box>
                                            )}
                                        </Box>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Box className='selected-data-show'>
                                            {selectedDistrictNames.map((name, index) => (
                                                <span key={index}>{name}</span>
                                            ))}
                                        </Box>
                                        <ContactButton className='district-btn' label={t('Continue')} icon={ButtonIcon} onClick={handleContinueClick} />
                                    </Modal.Footer>
                                </Modal>
                            )}
                        </Box>
                    </Col>

                    <Col lg={12} md={12}>
                        <Box className='field-box border-bottom-line'>
                            <HeadingStyled className='label-heading'>{t('Customer_type')}</HeadingStyled>
                            <Box className='active-radio-container'>
                                <input type="radio" id='seeker' value={t('Property_seeker')} name="customer_type" />
                                <LabelStyled className='input-field' for='seeker'>{t('Property_seeker')}</LabelStyled>
                                <input type="radio" id='Broker' value={t('Broker')} name="customer_type" />
                                <LabelStyled className='input-field' for='Broker'> {t('Broker')}</LabelStyled>
                                <input type="radio" id='Investor' value={t('Investor')} name="customer_type" />
                                <LabelStyled className='input-field' for='Investor'> {t('Investor')}</LabelStyled>
                            </Box>
                            {/* {formErrors.customerType && <div className="error-message">{formErrors.customerType}</div>} */}
                        </Box>
                    </Col>
                    <Box className='d-flex align-items-center gap-2 justify-content-end mt-3'>
                        {isLoading ? <p className='loading-btn'><span className='spinner'></span></p> : null}
                        <ContactButton type="submit" icon={ButtonIcon} label={t('submit')} />
                        {/* <p className='loading-btn'><span className='spinner'></span></p> */}
                    </Box>
                </Row>
            </form>

        </LeadFormWrapper >
    )
}

export default LeadForm

